import { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRelocalizationPoints,
  // getFleetListUser,
  relocalization,
} from "../../../apis";
import { FiNavigation2 } from "react-icons/fi";
import {
  getRobotDirectionbyMapbox,
  // robotmsgAction,
} from "../../../redux/actions";
import mapboxgl from "mapbox-gl";
// import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import { showErrorToast, showSuccessToast } from "../../toast/toastType";

let origin;
let destination;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const checkActionId = (commandListData) => {
  if (
    !sessionStorage.getItem("actionId") ||
    sessionStorage.getItem("actionId") === "null"
  ) {
    sessionStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    return true;
  } else {
    if (
      commandListData.actionId !==
      !!Number(sessionStorage.getItem("actionId")).actionId
    ) {
      sessionStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      return true;
    } else {
      return false;
    }
  }
};

const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;

const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

const isPointNearCircle = (
  pointLat,
  pointLng,
  centerLat,
  centerLng,
  radius
) => {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerLat) / 180.0) * ky;
  var dx = Math.abs(centerLng - pointLng) * kx;
  var dy = Math.abs(centerLat - pointLat) * ky;
  return Math.sqrt(dx * dx + dy * dy);
};

const MapboxMain = ({
  robotPathCheck,
  robotId,
  geofenceZone,
  nogoZones,
  fleetZones,
  robotLatitude,
  robotLongitude,
  width,
  slamPosition,
  setSlamPosition,
  difficultyLayerToggle,
  relocalisation,
}) => {
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  // const { robotmsgData } = useSelector((state) => state.robotMessageReducer);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [, setPolygonArray] = useState([]);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);
  const [navigationControlsRendered, setNavigationControlsRendered] =
    useState(false);
  const [relocationPointsData, setRelocationPointsData] = useState([]);

  const [nogoZonesCoordinates, setNogoZonesCoordinates] = useState(
    nogoZones.map((data) => [data.lng, data.lat])
  );
  const [geofenceCoordinates, setGeofenceCoordinates] = useState(
    geofenceZone.map((data) => [data.lng, data.lat])
  );

  const mapRef = useRef(null);
  let destinationMarkerForBound = [];

  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const fleetId = sessionStorage.getItem("fleetId");

  window.relocalizeRobot = function (token, robotId, metricX, metricY, yaw) {
    relocalization(token, robotId, metricX, metricY, yaw)
      .then((res) => {
        // console.log("Open Door 2 Response", res)
        showSuccessToast("Robot relocalized successfully!");
      })
      .catch((err) => {
        //  console.log('Open Door 2 Error',err)
        showErrorToast("Something went wrong. Unable to relocalize!!");
      });
  };

  window.showRelocalizeImage = function (imageId) {
    const selectedImage = document.getElementById(
      "relocalization__image__no__" + imageId
    );
    const selectedSpan = document.getElementById(
      "relocalization__image__span__no__" + imageId
    );

    if (selectedImage.style.display === "none") {
      selectedImage.style.display = "block";
    } else {
      selectedImage.style.display = "none";
    }

    if (selectedSpan.innerText === "View Image") {
      selectedSpan.innerText = "Hide Image";
    } else {
      selectedSpan.innerText = "View Image";
    }
  };

  class PitchToggle {
    constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");
      this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch };
          map.easeTo(options);
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
          setUserControllingMap(true);
          map.setLayoutProperty("building-extrusion", "visibility", "visible");
        } else {
          map.easeTo({ pitch: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          setUserControllingMap(true);
          map.setLayoutProperty("building-extrusion", "visibility", "none");
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  // robotMarkerDiv.style.backgroundImage =
  //   "url(/assets/images/robot-icon-pickup_1.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  // destinationMarkerDiv.style.visibility = "hidden";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/dropoff.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  // destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const updateRobotPath = () => {
    const map = mapRef.current;
    if (!robotPathCheck) {
      if (
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE")
        //   &&
        // map.isStyleLoaded()
      ) {
        const newrobotPath = robotDetails[robotId].path.map((pathDirection) => {
          if (!isLongitude(pathDirection.lng)) {
            // showErrorToast("Receiving Invalid Longitude for robot path!");
            return [];
          }

          if (!isLatitude(pathDirection.lat)) {
            // showErrorToast("Receiving Invalid Latitude for robot path!");
            return [];
          }

          return [pathDirection.lng, pathDirection.lat];
        });

        if (
          !newrobotPath.some(
            (element) => Array.isArray(element) && element.length === 0
          )
        ) {
          const mySource = map.getSource("robotPath");

          map &&
            mySource &&
            mySource.setData({
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: newrobotPath,
              },
            });

          if (
            !userControllingMap &&
            !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
          ) {
            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
              newrobotPath[0],
              newrobotPath[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of newrobotPath) {
              bounds.extend(coord);
            }

            bounds.extend([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ]);

            if (destinationMarkerForBound.length !== 0) {
              bounds.extend(destinationMarkerForBound);
            }

            map.fitBounds(bounds, {
              padding: 100,
              bearing: robotDetails[robotId].headingSlam
                ? parseFloat(robotDetails[robotId].headingSlam)
                : 0,
            });
          }

          map.getLayer("path") &&
            map.setPaintProperty("path", "line-color", "#0f0");
        }
      } else {
        const robotPathSource = map.getSource("robotPath");
        map &&
          robotPathSource &&
          robotPathSource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          });
      }
    } else {
      if (
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          .actionType.includes("MOVE") &&
        // map.isStyleLoaded() &&
        checkActionId(
          robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          )
        )
      ) {
        destination = {
          lat: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).latitude,
          lng: robotDetails[robotId].robotCommandList.find(
            (x) => x.actionPriority === 1
          ).longitude,
        };
        origin = {
          lat: robotLatitude,
          lng: robotLongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }

      if (robotDirections && robotDirections.hasOwnProperty("routes")) {
        const mapboxPath = robotDirections.routes[0].geometry.coordinates;
        let tempMapBoxPath = [...mapboxPath];
        let nearest = 0;
        tempMapBoxPath.forEach((path, index) => {
          if (
            isPointNearCircle(
              path[1],
              path[0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            ) <
            isPointNearCircle(
              tempMapBoxPath[nearest][1],
              tempMapBoxPath[nearest][0],
              parseFloat(robotDetails[robotId].latitude),
              parseFloat(robotDetails[robotId].longitude),
              0.01
            )
          ) {
            nearest = index;
          }
        });

        tempMapBoxPath.slice(nearest);

        const mySource = map.getSource("robotPath");

        map &&
          mySource &&
          mySource.setData({
            type: "FeatureCollection",
            name: "robotPaths",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: tempMapBoxPath,
                },
              },
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [
                      tempMapBoxPath.length && tempMapBoxPath[0][0],
                      tempMapBoxPath.length && tempMapBoxPath[0][1],
                    ],
                    [
                      slamPosition
                        ? robotDetails[robotId]?.longitudeSlam
                        : parseFloat(robotDetails[robotId]?.longitude),
                      slamPosition
                        ? robotDetails[robotId]?.latitudeSlam
                        : parseFloat(robotDetails[robotId]?.latitude),
                    ],
                  ],
                },
              },
            ],
          });
        map.getLayer("path") &&
          map.setPaintProperty("path", "line-color", "#A020F0");

        if (
          !userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
        ) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            tempMapBoxPath[0],
            tempMapBoxPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of tempMapBoxPath) {
            bounds.extend(coord);
          }

          bounds.extend([
            slamPosition
              ? parseFloat(robotDetails[robotId].longitudeSlam)
              : parseFloat(robotDetails[robotId].longitude),
            slamPosition
              ? parseFloat(robotDetails[robotId].latitudeSlam)
              : parseFloat(robotDetails[robotId].latitude),
          ]);

          map.fitBounds(bounds, {
            padding: 100,
            bearing: robotDetails[robotId].headingSlam
              ? parseFloat(robotDetails[robotId].headingSlam)
              : 0,
          });
        }
      }
    }
  };

  useEffect(() => {
    // axios
    //   .get(
    //     `${process.env.PUBLIC_URL}/assets/dataFiles/relocalization_points_with_id.csv`
    //   )
    getRelocalizationPoints(fleetId)
      .then((response) => {
        let relocalizationPoints = response?.data?.data?.filter(
          (location) => location._id === "RELOCALIZATION"
        )[0]
          ? response?.data?.data?.filter(
              (location) => location._id === "RELOCALIZATION"
            )[0].locations
          : [];

        setRelocationPointsData(relocalizationPoints);

        // const lines = response.data.split("\n");

        // if (lines.length > 1) {
        //   const headers = lines[0].split(",");

        //   const jsonResult = lines.slice(1).map((line) => {
        //     const values = line.split(",");
        //     return headers.reduce((obj, header, index) => {
        //       obj[header] = values[index];
        //       return obj;
        //     }, {});
        //   });

        //   console.log(jsonResult, "JSON RESULT")
        //   setRelocationPointsData(jsonResult);
        //   // console.log(relocationPointsData, "RELOCALISATION");
        // }
      })
      .catch((error) => {
        console.error("Error fetching CSV:", error);
      });
  }, []);

  useEffect(() => {
    if (!navigationControlsRendered) return;

    const navigationControls = document.querySelectorAll(
      ".mapboxgl-ctrl-group"
    );

    const handleNavigationButtonClick = (e) => {
      setUserControllingMap(true);
    };

    navigationControls.length > 0 &&
      navigationControls.forEach((control) => {
        control.addEventListener("mousedown", handleNavigationButtonClick);
        return () => {
          control.removeEventListener("mousedown", handleNavigationButtonClick);
        };
      });
  }, [navigationControlsRendered]);

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [
          slamPosition
            ? isLongitude(robotDetails[robotId].longitudeSlam)
              ? robotDetails[robotId].longitudeSlam
              : 0
            : isLongitude(robotDetails[robotId].longitude)
            ? robotDetails[robotId].longitude
            : 0,
          slamPosition
            ? isLatitude(robotDetails[robotId].latitudeSlam)
              ? robotDetails[robotId].latitudeSlam
              : 0
            : isLatitude(robotDetails[robotId].latitude)
            ? robotDetails[robotId].latitude
            : 0,
        ],
        zoom: zoom,
      });

      newMap.on("style.load", () => {
        newMap.setLayoutProperty("building-extrusion", "visibility", "none");
      });

      robotDetails[robotId].longitudeSlam
        ? setSlamPosition(true)
        : setSlamPosition(false);

      // Marker type for with/without heading
      robotMarkerDiv.style.backgroundImage =
        robotDetails[robotId].headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
          ? "url(/assets/images/robot-icon-2.svg)"
          : "url(/assets/images/robot-icon-pickup_1.svg)";

      // Initialize robot marker
      let newRobotMarker;

      if (
        robotDetails[robotId].headingSlam &&
        !!Number(process.env.REACT_APP_HEADING)
      ) {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? isLongitude(robotDetails[robotId].longitudeSlam)
                ? robotDetails[robotId].longitudeSlam
                : 0
              : isLongitude(robotDetails[robotId].longitude)
              ? robotDetails[robotId].longitude
              : 0,
            slamPosition
              ? isLatitude(robotDetails[robotId].latitudeSlam)
                ? robotDetails[robotId].latitudeSlam
                : 0
              : isLatitude(robotDetails[robotId].latitude)
              ? robotDetails[robotId].latitude
              : 0,
          ])
          .setRotation(parseFloat(robotDetails[robotId].headingSlam))
          .setRotationAlignment("map")
          .addTo(newMap);
      } else {
        newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
          .setLngLat([
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ])
          .addTo(newMap);
      }

      // Initialize destination marker
      const destinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);

      setDestinationMarker(destinationMarker);
      destinationMarkerForBound = [];

      newMap.on("load", () => {
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [nogoZonesCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",

              coordinates: [geofenceCoordinates],
            },
          },
        });

        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });

      newMap.on("wheel", () => {
        setUserControllingMap(true);
      });

      newMap.on("drag", () => {
        setUserControllingMap(true);
      });

      let polygonArray = [];

      fleetZones.map((polygon, index) => {
        polygonArray.push(polygon.map((data) => [data.lng, data.lat]));

        newMap.on("load", () => {
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",

                coordinates: [polygonArray[index]],
              },
            },
          });

          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "fill",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "fill-color": "#7859bc",
              "fill-opacity": 0.75,
            },
          });
        });

        return polygonArray;
      });

      // Add zoom and rotation controls to the map.
      newMap.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        })
      );

      setNavigationControlsRendered(true);

      newMap.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-right");

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });

      newMap.on("idle", function () {
        newMap.resize();
      });

      setPolygonArray(polygonArray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);
      mapRef.current = newMap;
    };

    if (!map) {
      initializeMap();
    }
    return () => {};
  }, [map, nogoZones, fleetZones, geofenceZone, setMap]);

  useEffect(() => {
    if (map && map.isStyleLoaded()) {
      if (
        !map.getSource("robotPath") &&
        ((robotDetails[robotId].hasOwnProperty("path") &&
          robotDetails[robotId].path.length > 0) ||
          (robotDetails[robotId].hasOwnProperty("robotCommandList") &&
            robotDetails[robotId].robotCommandList.length > 0 &&
            robotDetails[robotId].robotCommandList.find(
              (x) => x.actionPriority === 1
            ) &&
            robotDetails[robotId].robotCommandList
              .find((x) => x.actionPriority === 1)
              .actionType.includes("MOVE")))
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);

        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 5,
          },
        });
      }

      if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        robotDetails[robotId].hasOwnProperty("path") &&
        robotDetails[robotId].path.length > 0 &&
        robotDetails[robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[robotId].robotCommandList.length > 0 &&
        robotDetails[robotId].robotCommandList.find(
          (x) => x.actionPriority === 1
        ) &&
        robotDetails[robotId].robotCommandList
          .find((x) => x.actionPriority === 1)
          ?.actionType.includes("MOVE")
      ) {
        let newrobotPath = [];
        newrobotPath =
          robotDetails[robotId].path &&
          robotDetails[robotId].path.map((pathDirection) => {
            if (!isLongitude(pathDirection.lng)) {
              // showErrorToast("Receiving Invalid Longitude for robot path!");
              return [];
            }

            if (!isLatitude(pathDirection.lat)) {
              // showErrorToast("Receiving Invalid Latitude for robot path!");
              return [];
            }

            return [pathDirection.lng, pathDirection.lat];
          });

        if (
          !newrobotPath.some(
            (element) => Array.isArray(element) && element.length === 0
          )
        ) {
          // Create a 'LngLatBounds' with both corners at the first coordinate.
          const bounds = new mapboxgl.LngLatBounds(
            newrobotPath[0],
            newrobotPath[0]
          );

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of newrobotPath) {
            bounds.extend(coord);
          }

          map.fitBounds(bounds, {
            padding: 20,
          });
        }
      } else if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        nogoZonesCoordinates.length > 0
      ) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          nogoZonesCoordinates[0],
          nogoZonesCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of nogoZonesCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      } else if (
        !userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
        geofenceCoordinates.length > 0
      ) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          geofenceCoordinates[0],
          geofenceCoordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of geofenceCoordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      }

      if (!!Number(process.env.REACT_APP_RELOCALISATION)) {
        !map.hasImage("relocalisation-marker") &&
          map.loadImage(
            `${process.env.PUBLIC_URL}/assets/images/robot-icon-5.png`,
            (error, image) => {
              if (error) throw error;
              map.addImage("relocalisation-marker", image);
            }
          );

        !map.getSource("relocationPoints-0") &&
          relocationPointsData.map((data, index) => {
            if (data.status) {
              // console.log(data.Image_id)
              map.addSource(`relocationPoints-${index}`, {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  name: `Relocation-Point-${index}`,
                  features: [
                    {
                      type: "Feature",
                      properties: {
                        description: `
                      <div><strong>Name: </strong><span>${
                        data.locationName
                      }</span></div>
                      <div><strong>Map X: </strong><span>${
                        data.positionX
                      }</span></div>
                      <div><strong>Map Y: </strong><span>${
                        data.positionY
                      }</span></div>
                      <div><strong>Map Yaw: </strong><span>${
                        data.positionYaw
                      }</span></div>
                      <div><strong>Latitude: </strong><span>${
                        data.latitude
                      }</span></div>
                      <div><strong>Longitude: </strong><span>${
                        data.longitude
                      }</span></div>
                      <div><strong>Heading: </strong><span>${
                        data.headingAngle
                      }</span></div>
                      <div class="mt-2 w-full flex ${
                        data.imageLocationUrl && data.imageLocationUrl !== ""
                          ? "justify-between"
                          : "justify-start"
                      } items-center">
                      ${
                        data.imageLocationUrl && data.imageLocationUrl !== ""
                          ? `<span id="relocalization__image__span__no__${index}" class="border-b border-[#d04522] cursor-pointer font-poppins text-[11px] text-[#d04522]" onclick="showRelocalizeImage('${index}')">View Image</span>`
                          : ""
                      }
                      <button class="rounded-[4px] px-2 py-0.5 bg-[#d04522] text-white font-poppins text-[11px]" onclick="relocalizeRobot('${token}','${robotId}','${
                          data.positionX
                        }', '${data.positionY}', '${
                          data.positionYaw
                        }')">Relocalize</button>
                      </div>
                      <a></a>
                      <img id="relocalization__image__no__${index}" style="display:none;" class="mt-2 w-full h-auto" src="${
                          data.imageLocationUrl
                        }" alt="relocalization__point__image" />
                     `,
                      },
                      geometry: {
                        type: "Point",
                        coordinates: [data.longitude, data.latitude],
                      },
                    },
                  ],
                },
              });
            }

            return null;
          });

        !map.getLayer("relocationPointsLayer-0") &&
          relocationPointsData.map((data, index) => {
            if (data.status) {
              map.addLayer({
                id: `relocationPointsLayer-${index}`,
                type: "symbol",
                source: `relocationPoints-${index}`,
                layout: {
                  "icon-image": "relocalisation-marker",
                  "icon-size": 0.1,
                  "icon-allow-overlap": true,
                  "icon-rotate": parseFloat(data.headingAngle),
                  "icon-rotation-alignment": "map",
                  "text-allow-overlap": true,
                  "text-ignore-placement": true,
                  "icon-ignore-placement": true,
                },
                paint: {
                  "icon-opacity": 1,
                },
                filter: ["==", "$type", "Point"],
              });
              // When a click event occurs on a feature in the places layer, open a popup at the
              // location of the feature, with description HTML from its properties.
              map.on("click", `relocationPointsLayer-${index}`, (e) => {
                // Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                const description = e.features[0].properties.description;

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                new mapboxgl.Popup()
                  .setLngLat(coordinates)
                  .setHTML(description)
                  .addTo(map);
              });

              // Change the cursor to a pointer when the mouse is over the places layer.
              map.on("mouseenter", `relocationPointsLayer-${index}`, () => {
                map.getCanvas().style.cursor = "pointer";
              });

              // Change it back to a pointer when it leaves.
              map.on("mouseleave", `relocationPointsLayer-${index}`, () => {
                map.getCanvas().style.cursor = "";
              });
            }
            return null;
          });

        if (relocalisation) {
          map.getLayer("relocationPointsLayer-0") &&
            relocationPointsData.map((data, index) => {
              map.setLayoutProperty(
                `relocationPointsLayer-${index}`,
                "visibility",
                "visible"
              );
              return null;
            });
        } else {
          map.getLayer("relocationPointsLayer-0") &&
            relocationPointsData.map((data, index) => {
              map.setLayoutProperty(
                `relocationPointsLayer-${index}`,
                "visibility",
                "none"
              );
              return null;
            });
        }
      }
    }

    if (
      !userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
      !geofenceCoordinates.length > 0 &&
      !nogoZonesCoordinates.length > 0 &&
      // robotDetails[robotId].hasOwnProperty("path") &&
      // !robotDetails[robotId].path.length > 0 &&
      robotDetails[robotId].hasOwnProperty("robotCommandList") &&
      !robotDetails[robotId].robotCommandList.length > 0
    ) {
      map &&
        map.flyTo({
          center: [
            slamPosition
              ? robotDetails[robotId].longitudeSlam
              : robotDetails[robotId].longitude,
            slamPosition
              ? robotDetails[robotId].latitudeSlam
              : robotDetails[robotId].latitude,
          ],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          zoom: 15,
        });
    }

    // move robot marker
    if (robotMarker) {
      if (
        !!Number(process.env.REACT_APP_HEADING) &&
        (robotDetails[robotId].headingSlam || robotDetails[robotId].heading)
      ) {
        if (!!Number(process.env.REACT_APP_HEADING_ROTATION)) {
          robotMarker
            .setLngLat([
              slamPosition
                ? isLongitude(robotDetails[robotId].longitudeSlam)
                  ? robotDetails[robotId].longitudeSlam
                  : 0
                : isLongitude(robotDetails[robotId].longitude)
                ? robotDetails[robotId].longitude
                : 0,
              slamPosition
                ? isLatitude(robotDetails[robotId].latitudeSlam)
                  ? robotDetails[robotId].latitudeSlam
                  : 0
                : isLatitude(robotDetails[robotId].latitude)
                ? robotDetails[robotId].latitude
                : 0,
            ])
            .setRotation(
              parseFloat(
                robotDetails[robotId].headingSlam
                  ? robotDetails[robotId].headingSlam
                  : robotDetails[robotId].heading
              )
            )
            .setRotationAlignment("map");
        } else {
          robotMarker
            .setLngLat([
              slamPosition
                ? parseFloat(robotDetails[robotId].longitudeSlam)
                : parseFloat(robotDetails[robotId].longitude),
              slamPosition
                ? parseFloat(robotDetails[robotId].latitudeSlam)
                : parseFloat(robotDetails[robotId].latitude),
            ])
            .setRotationAlignment("map");
        }
      } else {
        robotMarker.setLngLat([
          slamPosition
            ? parseFloat(robotDetails[robotId].longitudeSlam)
            : parseFloat(robotDetails[robotId].longitude),
          slamPosition
            ? parseFloat(robotDetails[robotId].latitudeSlam)
            : parseFloat(robotDetails[robotId].latitude),
        ]);
      }
    }

    // Destination Marker on move
    if (
      destinationMarker &&
      robotDetails[robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[robotId].robotCommandList.length > 0 &&
      robotDetails[robotId].robotCommandList.find((x) => x.actionPriority === 1)
    ) {
      let destinationlng = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).longitude;
      let destinationlat = robotDetails[robotId].robotCommandList.find(
        (x) => x.actionPriority === 1
      ).latitude;

      if (destinationlng && destinationlat && isLatitude(destinationlat)&& isLongitude(destinationlng)) {
        // destinationMarkerDiv.style.visibility = "visible";
        destinationMarker.setLngLat([destinationlng, destinationlat]);

        if (
          !userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL)
        ) {
          const mySource = map.getSource("robotPath");
          try {
            if (map && mySource) {
              // Create a 'LngLatBounds' with both corners at the first coordinate.
              const bounds = new mapboxgl.LngLatBounds(
                mySource._options.data.geometry.coordinates[0],
                mySource._options.data.geometry.coordinates[0]
              );

              // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
              for (const coord of map &&
                mySource &&
                mySource._options.data.geometry.coordinates) {
                bounds.extend(coord);
              }

              bounds.extend([
                slamPosition
                  ? parseFloat(robotDetails[robotId].longitudeSlam)
                  : parseFloat(robotDetails[robotId].longitude),
                slamPosition
                  ? parseFloat(robotDetails[robotId].latitudeSlam)
                  : parseFloat(robotDetails[robotId].latitude),
              ]);

              bounds.extend([destinationlng, destinationlat]);

              destinationMarkerForBound = [destinationlng, destinationlat];

              map.fitBounds(bounds, {
                padding: 100,
                bearing: robotDetails[robotId].headingSlam
                  ? parseFloat(robotDetails[robotId].headingSlam)
                  : 0,
                duration: 200,
              });
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    } else {
      destinationMarker && destinationMarker.setLngLat([0, 0]);
    }

    setNogoZonesCoordinates(nogoZones.map((data) => [data.lng, data.lat]));

    setGeofenceCoordinates(geofenceZone.map((data) => [data.lng, data.lat]));

    let polygonArray = [];

    fleetZones.map((polygon, index) => {
      polygonArray.push(polygon.map((data) => [data.lng, data.lat]));
      return polygonArray;
    });

    setPolygonArray(polygonArray);
    if (robotDetails[robotId]) {
      updateRobotPath();
    }
  }, [
    robotDetails[robotId].longitude,
    robotDetails[robotId].latitude,
    robotMarker,
    destinationMarker,
    robotId,
    fleetZones,
    geofenceZone,
    nogoZones,
    robotPathCheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[robotId],
  ]);

  return (
    <>
      <div className="relative h-full" style={{ width: width }}>
        <div
          className={`sidebar ${
            width === "0%" ? "hidden" : ""
          } w-fit min-h-[35px] h-max bg-[rgba(35,55,75,0.7)] text-white p-[6px_12px] z-[1] absolute top-0 left-0 m-3 rounded-[4px]`}
        >
          <span className="text-sm">
            Ottobot Position [
            {!slamPosition
              ? robotDetails[robotId]?.latitude
              : robotDetails[robotId]?.latitudeSlam}
            ,
            {!slamPosition
              ? robotDetails[robotId]?.longitude
              : robotDetails[robotId]?.longitudeSlam},
            {!slamPosition
              ? robotDetails[robotId]?.altitude
              : robotDetails[robotId]?.altitudeSlam}
            ] | Zoom: {zoom}{" "}
            {robotDetails[robotId]?.slamConfidence &&
              ` | Slam Confidence: ${robotDetails[robotId]?.slamConfidence}`}
          </span>
        </div>
        {userControllingMap &&
          !!Number(process.env.REACT_APP_USER_MAP_CONTROL) && (
            <div
              className={`cursor-pointer ${
                width === "0%" ? "hidden" : "flex"
              } items-center gap-2 sidebar bg-white text-[#00B7D4] p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
              onClick={() => setUserControllingMap(false)}
            >
              <FiNavigation2 strokeWidth={3} />
              <span className="text-xs font-bold uppercase">Re-centre</span>
            </div>
          )}
        <div
          id="map-container-id"
          className="map-container"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </>
  );
};
export default memo(MapboxMain);
